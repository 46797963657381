@font-face {
  font-family: Poppins-Light;
  src: url('../fonts/poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: Poppins-Regular;
  src: url('../fonts/poppins/Poppins-Regular.ttf');
}

@font-face {
  font-family: Poppins-Medium;
  src: url('../fonts/poppins/Poppins-Medium.ttf');
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url('../fonts/poppins/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: Poppins-Bold;
  src: url('../fonts/poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: Poppins-ExtraBold;
  src: url('../fonts/poppins/Poppins-ExtraBold.ttf');
}

body {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

input,
.form-input,
select,
textarea,
button,
.form-control,
.input-field:focus,
.input-field:active {
  outline: 0px !important;
  -webkit-appearance: none !important;
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none !important;
}

a {
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
}

.logo {
  width: 200px;
}

.hero-section {
  background: #11140C;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-bottom-left-radius: 100% 100px;
  border-bottom-right-radius: 100% 100px;
}

.ca {
  background: #8BF968;
  font-size: 17px;
  color: #150D25;
  font-weight: 400;
  text-align: center;
  padding: 0.8rem;
}

.navbar-expand-lg .navbar-nav {
  align-items: center;
}

.hero-row {
  height: 80vh; /* Adjust this value to control how high the content goes */
  min-height: 500px; /* Or whatever minimum height you'd like */
}


.nav-link {
  font-family: Poppins-Regular;
  font-size: 16px;
  color: #FFFFFF !important;
  letter-spacing: 0;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.launchapp-btn {
  font-family: Poppins-Regular;
  font-size: 16px !important;
  color: #150D25 !important;
  background: #8BF968 !important;
  border: 1px solid #7FC5DF !important;
  border-radius: 30px !important;
  padding: 0.7rem 1.5rem !important;
}

.hero-left {
  display: flex;
  align-items: center;
  padding: 1rem 0 1rem;
  height: 100%;
}

.hero-title {
  font-family: Poppins-Bold;
  font-size: 100px;
  line-height: 80px;
  color: #FFFFFF;
  letter-spacing: 0;
  margin-bottom: 0;
}

.hero-text {
  font-family: Poppins-Regular;
  font-size: 17px;
  color: #FFFFFF;
  letter-spacing: 0;
}

.buy-now-btn {
  background: #8BF968;
  border: none;
  border-radius: 30px;
  width: 160px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 1rem;
  cursor: pointer;
  font-family: Poppins-Regular;
  font-size: 16px;
  color: #150D25;
  letter-spacing: 0;
  text-decoration: none;
  animation: shadow-pulse 1s infinite;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.3);
  }

  100% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

.brands img {
  height: 18px;
  margin: 1rem 0.5rem;
}

/* About/Mission Statement */
.about-section {
  padding: 7rem 0;
}

.dark_bg {
  background: #11140C;
}

.content-section {
  max-width: 900px;
  margin: auto;
}

.app-subtitle {
  border: 2px solid #150D25;
  border-radius: 29px;
  padding: 10px 20px;
  font-family: Poppins-Medium;
  font-size: 20px;
  color: #150D25;
  letter-spacing: 0;
  margin-bottom: 1.5rem;
  max-width: fit-content;
}

.section-title {
  font-family: Poppins-Bold;
  font-size: 40px;
  line-height: 45px;
  color: #150D25;
  letter-spacing: 0;
  padding-bottom: 2rem;
}

.about-section p,
ul li {
  font-family: Poppins-Regular;
  font-size: 18px;
  color: #000000;
  letter-spacing: 0;
  padding-bottom: 0.5rem;
}

.about-section h4 {
  font-family: Poppins-SemiBold;
  font-size: 25px;
  color: #000000;
  letter-spacing: 0;
  padding-bottom: 0.5rem;
}

.section-inner {
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 500px;
}

.about-section img {
  width: 100%;
  border-radius: 30px;
}

.token-image {
  overflow: hidden;
  border-radius: 30px;
  width: 100%;
  max-width: 500px;
}

.token-image:hover {
  box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
}

.token-image img:hover {
  transform: scale(1.1);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  /* transition: all .4s; */
}

/* hero-gradient-bg */
.hero-gradient-bg {
  /* background-image: linear-gradient(138deg, #150D25 0%, #E036FF 100%); */
  border-radius: 39px;
  background-position: bottom center;
  background: linear-gradient(-45deg,
      #11140C,
      #8BF968,
      #11140C,
      #11140C,
      #8BF968,
      #404833);
  background-size: 200% 200%;
  -webkit-animation: Gradient 5s ease infinite;
  -moz-animation: Gradient 5s ease infinite;
  animation: Gradient 5s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.hero-gradient-bg1 {
  height: 50px;
  text-align: center;
  overflow: hidden;
}

.hero-gradient-bg1 img {
  margin-top: -3px;
  position: relative;
  z-index: 0;
}

.hero-gradient-bg2 {
  height: 50px;
  overflow: hidden;
}

.hero-gradient-bg2 img {
  margin-bottom: -3px;
  margin-left: -3px;
  position: relative;
  z-index: 0;
}

.hero-gradient-1 {
  padding: 1rem 1rem;
  text-align: center;
}

.hero-gradient-1 h4 {
  font-family: Poppins-Bold;
  font-size: 32px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 0;
}

.hero-gradient-1 p {
  font-family: Poppins-Medium;
  font-size: 15px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.section-card {
  padding: 1.5rem;
  border-radius: 30px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.section-card:hover {
  box-shadow: rgba(203, 221, 240, 0.5) 0px 8px 24px;
}

.section-card ul {
  padding-left: 20px;
}

.whitepaper {
  margin: auto;
}

footer {
  background: #11140C;
  font-family: Poppins-Regular;
  font-size: 12px;
  color: #FFFFFF;
  letter-spacing: 0;
  text-align: center;
  padding: 1rem;
}

.circle {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  background: rgba(21,
      13,
      37,
      1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.circle:hover {
  background: rgba(21,
      13,
      37,
      0.65);
}

.circle img {
  width: 21px;
}

.mail {
  text-decoration: none;
  font-family: Poppins-Medium;
  font-size: 15px;
  color: #150D25;
  letter-spacing: 0;
}

.mail:hover {
  text-decoration: none;
  color: #2098D1;
}

/* Back Pulse */
@-webkit-keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}

@keyframes hvr-back-pulse {
  50% {
    background-color: rgba(32, 152, 209, 0.75);
  }
}

.hvr-back-pulse {
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  overflow: hidden;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: color, background-color;
  transition-property: color, background-color;
}

.hvr-back-pulse:hover,
.hvr-back-pulse:focus,
.hvr-back-pulse:active {
  -webkit-animation-name: hvr-back-pulse;
  animation-name: hvr-back-pulse;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  background-color: #DBCFBC;
  background-color: #DBCFBC;
  color: white;
  text-decoration: none;
}

.tokenomics-container {
  margin-bottom: 50px; /* Adjust the value as needed */
}

/* Bootstrap 5 custom styles */
@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1100px !important;
    /* max-width: 1320px; */
  }
}